@use '@on3/styles/base';

.header {
  padding-bottom: 5px;
}

.headerText {
  text-transform: capitalize;

  @extend %h3;
}

.footer {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding-top: 20px;

  @media screen and (min-width: 961px) {
    flex-direction: row;
  }
}

.footerLink {
  font-size: 12px;
  letter-spacing: 2.15px;
  line-height: 14px;
  margin-right: 20px;
  padding-right: 20px;

  &:last-of-type {
    border-right: unset;
  }

  @media screen and (min-width: 961px) {
    border-right: solid 1px #ddd;
  }
}
