@use '@on3/styles/variables' as var;

.container {
  padding-bottom: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid var.$lightNeutral;
  gap: 10px;
}

.image {
  border-radius: 5px;
  width: 100px;
  height: 100px;
  object-fit: cover;
  display: block;
  min-width: 100px;
}

.text {
  flex-grow: 1;
}

.title {
  font-family: var.$archivo-narrow;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0.35px;
  margin: 10px 0;
}
